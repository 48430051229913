<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">郵件紀錄</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div v-show="!grid.isEditing && grid.isRowSelected" id="batch-dropdown" class="dropdown ml-auto mr-2 sm:ml-0">
          <button
            class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center"
          >
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onPrint({ mode: 'selected' })"
              >
                <FontAwesome icon="print" type="fas" class="w-4 h-4 mr-2" /> 列印
              </span>
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'csv', mode: 'selected' })"
              >
                <FontAwesome icon="file-csv" type="fas" class="w-4 h-4 mr-2" /> 匯出 CSV
              </span>
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'html', mode: 'selected' })"
              >
                <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> 匯出 HTML
              </span>
            </div>
          </div>
        </div>
        <button v-show="grid.canCreateRow && !grid.isEditing" class="button text-white bg-theme-1 shadow-md mr-2 flex items-center" @click="grid.addNewRow">
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增
        </button>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input
              type="search"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              v-model.trim="grid.keyword"
              placeholder="請輸入關鍵字"
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full sm:w-16 bg-theme-1 text-white"
              @click="grid.refresh"
            >
              搜尋
            </button>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid
          ref="grid"
          v-bind="gridOptions"
          @edit="onGridEdit"
          @removeSelectedRows="onGridRemoveSelectedRows"
        >
          <template #modal="{ row }">
            <vxe-form class="ml-3" v-bind="formOptions" :data="row">
              <template #body="{ data }">
                <div style="padding-left:24px;">
                  <div>郵件內容</div>
                  <!-- <div style="padding:8px 8px; border: 2px solid #DCDFE6; border-radius: 5px; color:#718096; background-color:#E2E8F0"><vxe-div>{{ data.Body }}</vxe-div></div> -->
                  <div style="padding:8px 8px; border: 2px solid #DCDFE6; border-radius: 8px;"><vxe-div v-html="data.Body"></vxe-div></div>
                </div>
            </template>
            </vxe-form>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script lang="ts">
import CloudFun, { defineComponent, ref } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import formatDate from 'xe-utils/toDateString'

export default defineComponent({
  components: {
    Grid
  },
  setup () {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    var printColumns = [
      { field: 'Name' },
      { field: 'Description' }
    ]

    const gridOptions: GridOptions = {
      title: '郵件紀錄',
      canCreate: false,
      canUpdate: false,
      canDelete: false,
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      multiselect: false,
      printConfig: { sheetName: '郵件紀錄', columns: printColumns, modes: ['current', 'selected', 'all'] },
      exportConfig: { filename: '郵件紀錄', type: 'csv', types: ['html', 'csv'], mode: 'all', modes: ['current', 'selected', 'all'], columns: printColumns },
      columns: [
        { field: 'CreatedTime', title: '時間', showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => formatDate(new Date(cellValue), 'yyyy/MM/dd HH:mm:ss') },
        { field: 'Subject', title: '主旨', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "Type", title: "類型", showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => model && (cellValue === 2 || cellValue === 3 || cellValue === 5 || cellValue === 0) ? Object.values(model.enums.MailType).find(e => e.Value === cellValue)?.Name : '無' },
        { field: 'Message', title: '發送訊息', showHeaderOverflow: true, showOverflow: true, sortable: true, minWidth: '25%' },
      ],
      promises: {
        query: model ? (params) => {
          if (params?.sortings?.length === 0) {
            params.sortings.push({ column: 'CreatedTime', order: 1 });
          }
          console.log(params);
          return model.dispatch('mailLog/query', params)
        } : undefined,
        queryAll: model ? () => model.dispatch('mailLog/query') : undefined,
        save: model ? (params) => model.dispatch('mailLog/save', params) : undefined
      },
    }

    const formOptions: VxeFormProps = {
      titleWidth: 100,
      titleAlign: 'right',
      items: [
        { field: 'CreatedTime', title: '建立時間', span: 12, itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd HH:mm:ss' } } },
        { field: 'Id', title: '資料ID', span: 12, itemRender: { name: '$input' } },
        { field: 'Subject', title: '主旨', span: 12, itemRender: { name: '$input' } },
        { field: "Type", title: "類型", span: 12, itemRender: { name: "$select", options: model ? Object.values(model.enums.MailType).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
        { field: 'Message', title: '發送訊息', span: 24, itemRender: { name: '$input' } },
        { field: 'Recipient', title: '發送Email', span: 24, itemRender: { name: '$textarea', props: { autosize: { minRows: 4, maxRows: 4, type: 'string' } }, attrs: { type: 'textarea' } } },
        { span: 24, slots: { default: 'body' } }
        // { field: 'Body', title: '郵件內容', span: 24, itemRender: { name: '$textarea', props: { autosize: { minRows: 16, maxRows: 16, type: 'string' } }, attrs: { type: 'textarea' } } },
      ]
    }

    return {
      grid,
      gridOptions,
      formOptions,
    }
  },
  methods: {
    async onGridEdit(row: any, callback: any) {
      const entity = row.Id
        ? await this.$model.dispatch("mailLog/find", row.Id)
        : undefined;
      if (entity) {
        if (entity.Keywords && entity.Keywords.length) {
          entity.KeywordIds = entity.Keywords.map((e: any) => e.KeywordId);
        } else {
          entity.KeywordIds = [];
        }
        Object.assign(row, entity);
      }
      callback();
    },
    onGridRemoveSelectedRows (rows: any, callback: any) {
      cash('#batch-dropdown').dropdown('hide')
      callback()
    }
  }
})
</script>
