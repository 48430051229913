
import CloudFun, { defineComponent, ref } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import formatDate from 'xe-utils/toDateString'

export default defineComponent({
  components: {
    Grid
  },
  setup () {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    var printColumns = [
      { field: 'Name' },
      { field: 'Description' }
    ]

    const gridOptions: GridOptions = {
      title: '郵件紀錄',
      canCreate: false,
      canUpdate: false,
      canDelete: false,
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      multiselect: false,
      printConfig: { sheetName: '郵件紀錄', columns: printColumns, modes: ['current', 'selected', 'all'] },
      exportConfig: { filename: '郵件紀錄', type: 'csv', types: ['html', 'csv'], mode: 'all', modes: ['current', 'selected', 'all'], columns: printColumns },
      columns: [
        { field: 'CreatedTime', title: '時間', showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => formatDate(new Date(cellValue), 'yyyy/MM/dd HH:mm:ss') },
        { field: 'Subject', title: '主旨', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "Type", title: "類型", showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => model && (cellValue === 2 || cellValue === 3 || cellValue === 5 || cellValue === 0) ? Object.values(model.enums.MailType).find(e => e.Value === cellValue)?.Name : '無' },
        { field: 'Message', title: '發送訊息', showHeaderOverflow: true, showOverflow: true, sortable: true, minWidth: '25%' },
      ],
      promises: {
        query: model ? (params) => {
          if (params?.sortings?.length === 0) {
            params.sortings.push({ column: 'CreatedTime', order: 1 });
          }
          console.log(params);
          return model.dispatch('mailLog/query', params)
        } : undefined,
        queryAll: model ? () => model.dispatch('mailLog/query') : undefined,
        save: model ? (params) => model.dispatch('mailLog/save', params) : undefined
      },
    }

    const formOptions: VxeFormProps = {
      titleWidth: 100,
      titleAlign: 'right',
      items: [
        { field: 'CreatedTime', title: '建立時間', span: 12, itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd HH:mm:ss' } } },
        { field: 'Id', title: '資料ID', span: 12, itemRender: { name: '$input' } },
        { field: 'Subject', title: '主旨', span: 12, itemRender: { name: '$input' } },
        { field: "Type", title: "類型", span: 12, itemRender: { name: "$select", options: model ? Object.values(model.enums.MailType).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
        { field: 'Message', title: '發送訊息', span: 24, itemRender: { name: '$input' } },
        { field: 'Recipient', title: '發送Email', span: 24, itemRender: { name: '$textarea', props: { autosize: { minRows: 4, maxRows: 4, type: 'string' } }, attrs: { type: 'textarea' } } },
        { span: 24, slots: { default: 'body' } }
        // { field: 'Body', title: '郵件內容', span: 24, itemRender: { name: '$textarea', props: { autosize: { minRows: 16, maxRows: 16, type: 'string' } }, attrs: { type: 'textarea' } } },
      ]
    }

    return {
      grid,
      gridOptions,
      formOptions,
    }
  },
  methods: {
    async onGridEdit(row: any, callback: any) {
      const entity = row.Id
        ? await this.$model.dispatch("mailLog/find", row.Id)
        : undefined;
      if (entity) {
        if (entity.Keywords && entity.Keywords.length) {
          entity.KeywordIds = entity.Keywords.map((e: any) => e.KeywordId);
        } else {
          entity.KeywordIds = [];
        }
        Object.assign(row, entity);
      }
      callback();
    },
    onGridRemoveSelectedRows (rows: any, callback: any) {
      cash('#batch-dropdown').dropdown('hide')
      callback()
    }
  }
})
